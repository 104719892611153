"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const miscEntry = {
    type: "object",
    required: [
        "description",
        "appraisedValue",
        "priorYearValue",
        "cost",
    ],
    properties: {
        description: {
            $ref: "#/$defs/nonEmptyString",
        },
        make: {
            $ref: "#/$defs/nonEmptyString",
        },
        appraisedValue: {
            type: "number",
        },
        priorYearValue: {
            type: "number",
        },
        cost: {
            type: "number",
        },
        yearAcquired: {
            type: "string",
            format: "optionalYear",
        },
    },
};
const misc = {
    type: "object",
    oneOf: [
        {
            required: ["needToAdd"],
            properties: {
                needToAdd: {
                    enum: [false],
                },
            },
        },
        {
            required: ["needToAdd", "entries"],
            properties: {
                needToAdd: {
                    enum: [true],
                },
                entries: {
                    type: "array",
                    items: miscEntry,
                    minItems: 1,
                },
            },
        },
    ],
};
exports.default = misc;
