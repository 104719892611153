"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    type: "object",
    properties: {
        individualTaxpayers: {
            type: ["string", "null"],
        },
        corporationsEtc: {
            type: ["string", "null"],
        },
        fullTimeEmployeeTitle: {
            type: "string",
        },
        principalOfficeTaxpayerTitle: {
            type: "string",
        },
        business_type: {
            type: "string",
        },
        name: {
            type: "string",
        },
        phoneNumber: {
            type: "string",
        },
        faxNumber: {
            type: "string",
        },
        email: {
            type: "string",
            format: "email",
        },
        authorizedAgent: {
            type: "string",
        },
        signatureImage: {
            type: "object",
            required: ["signatureUuid"],
            properties: {
                signatureUuid: {
                    minLength: 1,
                    type: "string",
                },
            },
        },
    },
    required: ["signatureImage", "email"],
};
