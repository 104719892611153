import React, { FC } from "react";
import { Container, Form, Row, Col } from "react-bootstrap";

import landingHeroImage from "images/bpp-state-hero-icon.png";
import logo from "images/logos/taxscribeBus-logo-blue.png";
import AllStatesSelect from "./AllStatesSelect";
import styled from "styled-components";
import { BooleanGuard } from "@taxscribe/ui";
import { useParams } from "react-router-dom";
import StateLandingPage from ".";

const SelectContainer = styled(Container)`
  height: auto;
  flex-grow: 5;
`;

const AllWrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

const SelectPromptDiv = styled.div`
  margin-left: -30px;
`;

const SelectPromptWrapper = styled.span`
  background-color: #39adc1;
  padding: 6px;
  padding-left: 20px;
`;

const StateSelectorLandingPage: FC = () => {
  const { stateAbbr } = useParams();

  return (
    <main>
      <AllWrapper>
        <Container
          id="state-landing"
          fluid
          className="bg-bpp-light py-3 mb-5"
        >
          <img
            className="img-fluid ms-2"
            alt="taxscribe-header-logo"
            width="250"
            src={logo}
          />{" "}
          <Container className="col-xxl-11 px-1">
            <Row className="flex-lg-row-reverse g-5 justify-content-center">
              <Col className="col-10 col-sm-8 col-lg-6 mt-2">
                <img
                  src={landingHeroImage}
                  className="d-block mx-lg-auto img-fluid"
                  alt="Taxes"
                  width="600"
                  height="400"
                  loading="lazy"
                />{" "}
                <h1 className="text-center ts-primary">Save. Send. Simple.</h1>
              </Col>
              <Col className="col-lg-6 py-5">
                <h1 className="display-3 fw-semibold">
                  Filing business personal property in your state made easy!
                </h1>
              </Col>
            </Row>
          </Container>
          <SelectPromptDiv
            id="select-state"
            className="p-2 text-center"
          >
            <h3 className="h4 text-white mb-0 text-nowrap">
              <SelectPromptWrapper>
                SELECT YOUR FILING JURISDICTION:
              </SelectPromptWrapper>
            </h3>
          </SelectPromptDiv>
        </Container>

        <SelectContainer className="bg-light p-4">
          <Row className="justify-content-center">
            <Col md={4}>
              <Form.Label className="fs-4">Select Your State</Form.Label>
              <div>
                <AllStatesSelect />
              </div>
            </Col>
            <BooleanGuard condition={Boolean(stateAbbr)}>
              <StateLandingPage />
            </BooleanGuard>
            <Col />
          </Row>
        </SelectContainer>
      </AllWrapper>
    </main>
  );
};

export default StateSelectorLandingPage;
