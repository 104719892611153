"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.aircraftData = exports.aircraftDataEntry = void 0;
exports.aircraftDataEntry = {
    year: "",
    body: "",
    make: "",
    model: "",
    cost: 0,
    yearAcquired: "",
    airport: "",
    hangarNumber: "",
    tailNumber: "",
    PropertyPk: null,
};
exports.aircraftData = {
    needToAdd: null,
    aircraft: [],
};
