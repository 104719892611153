"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.watercraftData = exports.watercraftDataEntry = void 0;
const lengthEntry = {
    feet: 0,
    inches: 0,
};
exports.watercraftDataEntry = {
    PropertyPk: null,
    year: "",
    make: "",
    model: "",
    length: lengthEntry,
    registrationNumber: "",
    fuelCode: "",
    modelCode: "",
    propulsionCode: "",
    primaryUseCode: "",
    yearAcquired: "",
    cost: 0,
    marina: "",
    motorMakeOne: "",
    motorMakeTwo: "",
    slipNumber: "",
    motorHpOne: 0,
    motorHpTwo: 0,
    slipNumberExtension: "",
    motorSerialNumberOne: "",
    motorSerialNumberTwo: "",
};
exports.watercraftData = {
    needToAdd: null,
    watercraft: [],
};
