import React, { FC, useMemo } from "react";
import { useQuery } from "@apollo/client";
import { deepPurgeValues } from "@taxscribe/utils";
import { useJurisdictionContext } from "@taxscribe/ui";
import ViewListing from "./ViewListing";
import {
  GET_NEW_FORM_FOR_BUSINESS,
  NewFormForBusinessResults,
} from "./queries";
import LoadingPage from "components/LoadingPage";
import ErrorBoundary from "components/shared/ErrorBoundary";
import useFilteredFormData from "hooks/useCleansedFormData";
import { Metadata } from "@taxscribe/form";

export interface NewFormForBusinessProps {
  metadata: Metadata;
  accountNumberPrimary: string;
}

const NewFormForBusiness: FC<NewFormForBusinessProps> = ({
  metadata,
  accountNumberPrimary,
}) => {
  const { jurisdictionId } = useJurisdictionContext();
  const { data, loading, error } = useQuery<NewFormForBusinessResults>(
    GET_NEW_FORM_FOR_BUSINESS,
    {
      variables: {
        formType: "Listing",
        accountNumberPrimary,
        jurisdictionId,
      },
    },
  );

  const additionalMetadata = useMemo(() => {
    return {
      ...metadata,
      source: "Jurisdiction",
    };
  }, [metadata]);

  const filteredData = useFilteredFormData(data?.ncptsFormData?.data);
  const filteredFormData = useMemo(() => {
    if (!filteredData) return null;

    const purgedData = deepPurgeValues(null, filteredData);
    return purgedData;
  }, [filteredData]);

  if (loading || !filteredFormData) {
    return <LoadingPage />;
  }

  if (error) {
    return (
      <div role="main">
        <ErrorBoundary />
      </div>
    );
  }

  return (
    <ViewListing
      source="Jurisdiction"
      metadata={additionalMetadata}
      initialState={filteredFormData}
      rawNcpts={data?.ncptsFormData?.rawNcpts}
    />
  );
};

export default NewFormForBusiness;
