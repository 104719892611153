"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deepPurgeWithRegex = void 0;
const deepPurgeWithRegex = (attr, obj) => {
    if (!obj)
        return obj;
    const entries = Object.entries(obj);
    const accumulator = {};
    const result = entries.reduce((acc, [key, value]) => {
        if (attr.test(key))
            return acc;
        if (Array.isArray(value)) {
            return Object.assign(Object.assign({}, acc), { [key]: value.map((item) => {
                    if (item && typeof item === "object") {
                        return (0, exports.deepPurgeWithRegex)(attr, item);
                    }
                    return item;
                }) });
        }
        if (typeof value === "object") {
            return Object.assign(Object.assign({}, acc), { [key]: (0, exports.deepPurgeWithRegex)(attr, value) });
        }
        return Object.assign(Object.assign({}, acc), { [key]: value });
    }, accumulator);
    return result;
};
exports.deepPurgeWithRegex = deepPurgeWithRegex;
