import React, { FC, useMemo } from "react";
import { deepPurgeValues } from "@taxscribe/utils";

import ViewListing from "./ViewListing";
import { FormQueryResults, GET_FORM } from "./queries";
import useExistingFormData from "hooks/useExistingFormData";
import { useParams } from "react-router-dom";
import NewFormForBusiness from "./NewFormForBusiness";
import useFilteredFormData from "hooks/useCleansedFormData";
import useNewFormMetadata from "hooks/useNewFormMetadata";

interface ExistingFormArgs {
  formUuid: string;
}

const ExistingForm: FC<ExistingFormArgs> = ({ formUuid }) => {
  const { loading, data, formData, currentSubmission } = useExistingFormData<
    FormQueryResults["form"]["data"]
  >({ formUuid, query: GET_FORM });

  const source = data?.form.source;

  const filteredFormData = useFilteredFormData(formData);
  const extendedFormData = useMemo(() => {
    if (!filteredFormData) return;

    return deepPurgeValues(null, filteredFormData);
  }, [filteredFormData]);

  const filingYear = String(data?.form?.filingYear || "");
  const metadata = useMemo(() => {
    if (currentSubmission) return { ...currentSubmission, source, filingYear, app: "TSB" };
    return { source, filingYear, app: "TSB" };
  }, [filingYear, currentSubmission, source]);
  if (loading) return null;

  return (
    <ViewListing
      source={source}
      formUuid={formUuid}
      metadata={metadata}
      filingYear={filingYear}
      initialState={extendedFormData}
    />
  );
};

const NCListingPage: FC = () => {
  const { accountNumberPrimary, formUuid } = useParams();
  const { metadata: newFormMetadata, loading } = useNewFormMetadata();

  if (formUuid) {
    return (
      <ExistingForm formUuid={formUuid} />
    );
  }

  if (loading) return null;
  if (accountNumberPrimary) {
    return (
      <NewFormForBusiness
        metadata={newFormMetadata}
        accountNumberPrimary={accountNumberPrimary}
      />
    );
  }

  return (
    <ViewListing metadata={newFormMetadata} />
  );
};

export default NCListingPage;
