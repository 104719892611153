import React, { FC, PropsWithChildren, useMemo } from "react";
import { gql } from "@apollo/client";
import styled from "styled-components";
import { Metadata, NCExtension } from "@taxscribe/form";
import { useAuth } from "@reasongcp/react-fire-sub";
import { NCExtensionProviderProps } from "@taxscribe/form";

import { Row, Col, Card, Button } from "react-bootstrap";
import GenericFormWrapper from "components/shared/GenericFormWrapper";
import FormHeaderActions from "components/FormHeaderActions";
import { useFormDeadline, useGetFormFieldSignature, useJurisdictionContext } from "@taxscribe/ui";
import DeadlineGuard, {
  DeadlineGuardProps,
  GuardMessageProps,
} from "components/DeadlineGuard";
import { Link } from "react-router-dom";
import { useTaxbaseFormActions } from "@taxscribe/ui";
import useSubmitForm from "hooks/useSubmitForm";

interface ViewExtensionProps extends Partial<NCExtensionProviderProps> {
  formUuid?: string;
  metadata: Metadata;
  filingDeadline?: string;
  filingYear?: string | number;
}

const CREATE_EXTENSION = gql`
  mutation CreateNCExtension(
    $source: String!
    $formData: NCExtensionDataInput!
    $businessName: String
    $jurisdiction: String!
  ) {
    createNCExtension(
      source: $source
      formData: $formData
      businessName: $businessName
      jurisdiction: $jurisdiction
    )
  }
`;

const UPDATE_EXTENSION = gql`
  mutation UpdateNCExtension(
    $formUuid: String!
    $formData: NCExtensionDataInput!
  ) {
    updateNCExtension(uuid: $formUuid, formData: $formData)
  }
`;

const creationMutationName = "createNCExtension";

// TODO: Make this reach out for the deadline if not already here.
const getDeadlineDisplay = (filingDeadline?: string) => {
  if (!filingDeadline) return `01/31/${new Date().getFullYear()}`;
  return new Date(filingDeadline).toLocaleDateString();
};

const ExtensionGuard: FC<
  PropsWithChildren<Omit<DeadlineGuardProps, "formType">>
> = (props) => {
  return (
    <DeadlineGuard
      {...props}
      formType="extension"
    />
  );
};

const StyledDeadlineHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const GuardMessage: FC<GuardMessageProps> = ({ timezone, deadline }) => {
  const { webPath, jurisdictionName } = useJurisdictionContext();
  return (
    <Card.Body>
      <StyledDeadlineHeader>
        <h4 className="display-6 mb-4">Filing Deadline Has Passed</h4>
        <Link
          to={`/${webPath}/dashboard`}
          className="text-dark text-nowrap py-0 text-decoration-none"
        >
          <Button variant="info">Return to dashboard</Button>
        </Link>
      </StyledDeadlineHeader>
      <p className="text-muted">
        The deadline for filing an extension for {jurisdictionName} has passed
        as of {deadline?.toLocaleDateString("en-US", { timeZone: timezone })}.
      </p>
    </Card.Body>
  );
};

const ViewExtension: FC<ViewExtensionProps> = ({
  formUuid,
  metadata,
  filingYear,
  initialState,
  filingDeadline,
}) => {
  const { afterDeadline } = useFormDeadline("extension");
  const { name, hasEfile } = useJurisdictionContext();
  const { displayName, email } = useAuth() || {};
  const submit = useSubmitForm();
  const { saveAndSubmit, handleSave, readOnly } = useTaxbaseFormActions<
    typeof creationMutationName
  >({
    submit,
    formUuid,
    jurisdiction: name,
    creationMutationName,
    source: metadata?.source,
    status: metadata?.status,
    updateMutation: UPDATE_EXTENSION,
    createMutation: CREATE_EXTENSION,
  });

  const handleSignaturePrompt = useGetFormFieldSignature();

  const metadataWithUserInfo = useMemo(() => {
    // Favor the metadata attached to the form submission if it exists.
    if (metadata?.contactName) return metadata;
    return {
      ...metadata,
      email: metadata?.email || email,
      contactName: displayName || email || "",
    };
  }, [metadata, displayName, email]);

  const deadline = getDeadlineDisplay(filingDeadline);
  return (
    <NCExtension.Provider
      renderingApp="TSB"
      readOnly={readOnly}
      onSave={handleSave}
      hasEfile={hasEfile}
      onSubmit={saveAndSubmit}
      hideSubmit={afterDeadline}
      initialState={initialState}
      metadata={metadataWithUserInfo}
      onSignaturePrompt={handleSignaturePrompt}
    >
      <GenericFormWrapper
        formUuid={formUuid}
        formType="extension"
        filingYear={filingYear}
        FormGuard={ExtensionGuard}
        GuardMessage={GuardMessage}
        header="Business Personal Property Extension"
        headerActions={
          <FormHeaderActions
            formUuid={formUuid}
            readOnly={readOnly}
            metadata={metadata}
          />
        }
      >
        <Row>
          <Col>
            <NCExtension.ExtensionForm filingDeadline={deadline} />
          </Col>
        </Row>
      </GenericFormWrapper>
    </NCExtension.Provider>
  );
};

export default ViewExtension;
