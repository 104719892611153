"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ippSample11_25_2025 = void 0;
exports.ippSample11_25_2025 = {
    results: [
        {
            AbstractNumber: "0000013577202520250000",
            Value: {
                OwnershipInformation: {
                    OwnerValidValues: {
                        OwnershipTypes: [
                            {
                                DisplayValue: "OTHERS",
                                NcptsCode: "OWTYOTH",
                            },
                            {
                                DisplayValue: "BUSINESS",
                                NcptsCode: "OWTYBUS",
                            },
                            {
                                DisplayValue: "EXEMPT",
                                NcptsCode: "OWTYXPT",
                            },
                            {
                                DisplayValue: "INDIVIDUAL",
                                NcptsCode: "OWTYIND",
                            },
                            {
                                DisplayValue: "GOVERNMENT",
                                NcptsCode: "OWTYGOV",
                            },
                        ],
                        States: [
                            {
                                DisplayValue: null,
                                NcptsCode: null,
                            },
                            {
                                DisplayValue: "MISSOURI",
                                NcptsCode: "STATMO",
                            },
                            {
                                DisplayValue: "ONTARIO",
                                NcptsCode: "STATON",
                            },
                            {
                                DisplayValue: "COLORADO",
                                NcptsCode: "STATCO",
                            },
                            {
                                DisplayValue: "NEW YORK",
                                NcptsCode: "STATNY",
                            },
                            {
                                DisplayValue: "LOUISIANA",
                                NcptsCode: "STATLA",
                            },
                            {
                                DisplayValue: "IDAHO",
                                NcptsCode: "STATID",
                            },
                            {
                                DisplayValue: "OKLAHOMA",
                                NcptsCode: "STATOK",
                            },
                            {
                                DisplayValue: "PUERTO RICO",
                                NcptsCode: "STATPR",
                            },
                            {
                                DisplayValue: "PENNSYLVANIA",
                                NcptsCode: "STATPA",
                            },
                            {
                                DisplayValue: "VERMONT",
                                NcptsCode: "STATVT",
                            },
                            {
                                DisplayValue: "WISCONSIN",
                                NcptsCode: "STATWI",
                            },
                            {
                                DisplayValue: "INDIANA",
                                NcptsCode: "STATIN",
                            },
                            {
                                DisplayValue: "WYOMING",
                                NcptsCode: "STATWY",
                            },
                            {
                                DisplayValue: "OHIO",
                                NcptsCode: "STATOH",
                            },
                            {
                                DisplayValue: "HAWAII",
                                NcptsCode: "STATHI",
                            },
                            {
                                DisplayValue: "MISSISSIPPI",
                                NcptsCode: "STATMS",
                            },
                            {
                                DisplayValue: "TEXAS",
                                NcptsCode: "STATTX",
                            },
                            {
                                DisplayValue: "NEW MEXICO",
                                NcptsCode: "STATNM",
                            },
                            {
                                DisplayValue: "SOUTH DAKOTA",
                                NcptsCode: "STATSD",
                            },
                            {
                                DisplayValue: "NEW HAMPSHIRE",
                                NcptsCode: "STATNH",
                            },
                            {
                                DisplayValue: "MARYLAND",
                                NcptsCode: "STATMD",
                            },
                            {
                                DisplayValue: "NEBRASKA",
                                NcptsCode: "STATNE",
                            },
                            {
                                DisplayValue: "MICHIGAN",
                                NcptsCode: "STATMI",
                            },
                            {
                                DisplayValue: "KENTUCKY",
                                NcptsCode: "STATKY",
                            },
                            {
                                DisplayValue: "OREGON",
                                NcptsCode: "STATOR",
                            },
                            {
                                DisplayValue: "UTAH",
                                NcptsCode: "STATUT",
                            },
                            {
                                DisplayValue: "ALABAMA",
                                NcptsCode: "STATAL",
                            },
                            {
                                DisplayValue: "SOUTH CAROLINA",
                                NcptsCode: "STATSC",
                            },
                            {
                                DisplayValue: "ARIZONA",
                                NcptsCode: "STATAZ",
                            },
                            {
                                DisplayValue: "NORTHERN MARIANA ISLANDS",
                                NcptsCode: "STATMP",
                            },
                            {
                                DisplayValue: "FEDERATED STATES OF MICRONESIA",
                                NcptsCode: "STATFM",
                            },
                            {
                                DisplayValue: "ARKANSAS",
                                NcptsCode: "STATAR",
                            },
                            {
                                DisplayValue: "NEW JERSEY",
                                NcptsCode: "STATNJ",
                            },
                            {
                                DisplayValue: "IOWA",
                                NcptsCode: "STATIA",
                            },
                            {
                                DisplayValue: "VIRGIN ISLANDS",
                                NcptsCode: "STATVI",
                            },
                            {
                                DisplayValue: "DELAWARE",
                                NcptsCode: "STATDE",
                            },
                            {
                                DisplayValue: "ILLINOIS",
                                NcptsCode: "STATIL",
                            },
                            {
                                DisplayValue: "MONTANA",
                                NcptsCode: "STATMT",
                            },
                            {
                                DisplayValue: "MARSHALL ISLANDS",
                                NcptsCode: "STATMH",
                            },
                            {
                                DisplayValue: "GUAM",
                                NcptsCode: "STATGU",
                            },
                            {
                                DisplayValue: "WASHINGTON",
                                NcptsCode: "STATWA",
                            },
                            {
                                DisplayValue: "CALIFORNIA",
                                NcptsCode: "STATCA",
                            },
                            {
                                DisplayValue: "FLORIDA",
                                NcptsCode: "STATFL",
                            },
                            {
                                DisplayValue: "ARMED FORCES PACIFIC",
                                NcptsCode: "STATAP",
                            },
                            {
                                DisplayValue: "ARMED FORCES AMERICA",
                                NcptsCode: "STATAA",
                            },
                            {
                                DisplayValue: "NORTH DAKOTA",
                                NcptsCode: "STATND",
                            },
                            {
                                DisplayValue: "PALAU",
                                NcptsCode: "STATPW",
                            },
                            {
                                DisplayValue: "KANSAS",
                                NcptsCode: "STATKS",
                            },
                            {
                                DisplayValue: "MINNESOTA",
                                NcptsCode: "STATMN",
                            },
                            {
                                DisplayValue: "GEORGIA",
                                NcptsCode: "STATGA",
                            },
                            {
                                DisplayValue: "NORTH CAROLINA",
                                NcptsCode: "STATNC",
                            },
                            {
                                DisplayValue: "TENNESSEE",
                                NcptsCode: "STATTN",
                            },
                            {
                                DisplayValue: "VIRGINIA",
                                NcptsCode: "STATVA",
                            },
                            {
                                DisplayValue: "DISTRICT OF COLUMBIA",
                                NcptsCode: "STATDC",
                            },
                            {
                                DisplayValue: "CONNECTICUT",
                                NcptsCode: "STATCT",
                            },
                            {
                                DisplayValue: "MAINE",
                                NcptsCode: "STATME",
                            },
                            {
                                DisplayValue: "RHODE ISLAND",
                                NcptsCode: "STATRI",
                            },
                            {
                                DisplayValue: "NEVADA",
                                NcptsCode: "STATNV",
                            },
                            {
                                DisplayValue: "WEST VIRGINIA",
                                NcptsCode: "STATWV",
                            },
                            {
                                DisplayValue: "ALASKA",
                                NcptsCode: "STATAK",
                            },
                            {
                                DisplayValue: "ARMED FORCES AFRICA, CANADA, EUROPE, MIDDLE EAST",
                                NcptsCode: "STATAE",
                            },
                            {
                                DisplayValue: "AMERICAN SAMOA",
                                NcptsCode: "STATAS",
                            },
                            {
                                DisplayValue: "MASSACHUSETTS",
                                NcptsCode: "STATMA",
                            },
                        ],
                        PhoneTypes: [
                            {
                                DisplayValue: "PHONETREE",
                                NcptsCode: "PHTYPHTR",
                            },
                            {
                                DisplayValue: "HOME",
                                NcptsCode: "PHTYHOM",
                            },
                            {
                                DisplayValue: "E-PAYER",
                                NcptsCode: "PHTYEPAY",
                            },
                            {
                                DisplayValue: "FAX",
                                NcptsCode: "PHTYFAX",
                            },
                            {
                                DisplayValue: "CELLULAR",
                                NcptsCode: "PHTYCEL",
                            },
                            {
                                DisplayValue: "WORK",
                                NcptsCode: "PHTYWRK",
                            },
                            {
                                DisplayValue: "UNKNOWN",
                                NcptsCode: "PHTYUNK",
                            },
                        ],
                        BusinessTypes: [
                            {
                                DisplayValue: null,
                                NcptsCode: null,
                            },
                            {
                                DisplayValue: "DOING BUSINESS AS",
                                NcptsCode: "BUNTDBA",
                            },
                            {
                                DisplayValue: "FORMER",
                                NcptsCode: "BUNTFRMR",
                            },
                            {
                                DisplayValue: "TRADE",
                                NcptsCode: "BUNTTRAD",
                            },
                            {
                                DisplayValue: "AS KNOWN AS",
                                NcptsCode: "BUNTAKA",
                            },
                        ],
                        Counties: [
                            {
                                DisplayValue: null,
                                NcptsCode: null,
                            },
                            {
                                DisplayValue: "ROWAN",
                                NcptsCode: "CNTYROWAN",
                            },
                            {
                                DisplayValue: "CLAY",
                                NcptsCode: "CNTYCLAY",
                            },
                            {
                                DisplayValue: "BEAUFORT",
                                NcptsCode: "CNTYBEAUF",
                            },
                            {
                                DisplayValue: "PERSON",
                                NcptsCode: "CNTYPERSO",
                            },
                            {
                                DisplayValue: "ANSON",
                                NcptsCode: "CNTYANSON",
                            },
                            {
                                DisplayValue: "ROCKINGHAM",
                                NcptsCode: "CNTYROCKI",
                            },
                            {
                                DisplayValue: "ASHE",
                                NcptsCode: "CNTYASHE",
                            },
                            {
                                DisplayValue: "HAYWOOD",
                                NcptsCode: "CNTYHAYWO",
                            },
                            {
                                DisplayValue: "PERQUIMANS",
                                NcptsCode: "CNTYPERQU",
                            },
                            {
                                DisplayValue: "HYDE",
                                NcptsCode: "CNTYHYDE",
                            },
                            {
                                DisplayValue: "CHOWAN",
                                NcptsCode: "CNTYCHOWA",
                            },
                            {
                                DisplayValue: "FRANKLIN",
                                NcptsCode: "CNTYFRANK",
                            },
                            {
                                DisplayValue: "CLEVELAND",
                                NcptsCode: "CNTYCLEVE",
                            },
                            {
                                DisplayValue: "GUILFORD",
                                NcptsCode: "CNTYGUILF",
                            },
                            {
                                DisplayValue: "RICHMOND",
                                NcptsCode: "CNTYRICHM",
                            },
                            {
                                DisplayValue: "RANDOLPH",
                                NcptsCode: "CNTYRANDO",
                            },
                            {
                                DisplayValue: "SCOTLAND",
                                NcptsCode: "CNTYSCOTL",
                            },
                            {
                                DisplayValue: "BUNCOMBE",
                                NcptsCode: "CNTYBUNCO",
                            },
                            {
                                DisplayValue: "STOKES",
                                NcptsCode: "CNTYSTOKE",
                            },
                            {
                                DisplayValue: "GREENE",
                                NcptsCode: "CNTYGREEN",
                            },
                            {
                                DisplayValue: "HOKE",
                                NcptsCode: "CNTYHOKE",
                            },
                            {
                                DisplayValue: "TYRRELL",
                                NcptsCode: "CNTYTYRRE",
                            },
                            {
                                DisplayValue: "ALLEGANY",
                                NcptsCode: "CNTYALLEG",
                            },
                            {
                                DisplayValue: "ALEXANDER",
                                NcptsCode: "CNTYALEXA",
                            },
                            {
                                DisplayValue: "YANCEY",
                                NcptsCode: "CNTYYANCE",
                            },
                            {
                                DisplayValue: "VANCE",
                                NcptsCode: "CNTYVANCE",
                            },
                            {
                                DisplayValue: "HENDERSON",
                                NcptsCode: "CNTYHENDE",
                            },
                            {
                                DisplayValue: "SURRY",
                                NcptsCode: "CNTYSURRY",
                            },
                            {
                                DisplayValue: "WAYNE",
                                NcptsCode: "CNTYWAYNE",
                            },
                            {
                                DisplayValue: "CRAVEN",
                                NcptsCode: "CNTYCRAVE",
                            },
                            {
                                DisplayValue: "TRANSYLVANIA",
                                NcptsCode: "CNTYTRANS",
                            },
                            {
                                DisplayValue: "CABARRUS",
                                NcptsCode: "CNTYCABAR",
                            },
                            {
                                DisplayValue: "WATAUGA",
                                NcptsCode: "CNTYWATAU",
                            },
                            {
                                DisplayValue: "CAMDEN",
                                NcptsCode: "CNTYCAMDE",
                            },
                            {
                                DisplayValue: "WILSON",
                                NcptsCode: "CNTYWILSO",
                            },
                            {
                                DisplayValue: "LINCOLN",
                                NcptsCode: "CNTYLINCO",
                            },
                            {
                                DisplayValue: "MECKLENBURG",
                                NcptsCode: "CNTYMECKL",
                            },
                            {
                                DisplayValue: "WASHINGTON",
                                NcptsCode: "CNTYWASHI",
                            },
                            {
                                DisplayValue: "JACKSON",
                                NcptsCode: "CNTYJACKS",
                            },
                            {
                                DisplayValue: "CASWELL",
                                NcptsCode: "CNTYCASWE",
                            },
                            {
                                DisplayValue: "MARTIN",
                                NcptsCode: "CNTYMARTI",
                            },
                            {
                                DisplayValue: "ALAMANCE",
                                NcptsCode: "CNTYALAMA",
                            },
                            {
                                DisplayValue: "PENDER",
                                NcptsCode: "CNTYPENDE",
                            },
                            {
                                DisplayValue: "NASH",
                                NcptsCode: "CNTYNASH",
                            },
                            {
                                DisplayValue: "MITCHELL",
                                NcptsCode: "CNTYMITCH",
                            },
                            {
                                DisplayValue: "DURHAM",
                                NcptsCode: "CNTYDURHA",
                            },
                            {
                                DisplayValue: "MACON",
                                NcptsCode: "CNTYMACON",
                            },
                            {
                                DisplayValue: "FORSYTH",
                                NcptsCode: "CNTYFORSY",
                            },
                            {
                                DisplayValue: "CATAWBA",
                                NcptsCode: "CNTYCATAW",
                            },
                            {
                                DisplayValue: "LENOIR",
                                NcptsCode: "CNTYLENOI",
                            },
                            {
                                DisplayValue: "NEW HANOVER",
                                NcptsCode: "CNTYNEW H",
                            },
                            {
                                DisplayValue: "STANLEY",
                                NcptsCode: "CNTYSTANL",
                            },
                            {
                                DisplayValue: "BRUNSWICK",
                                NcptsCode: "CNTYBRUNS",
                            },
                            {
                                DisplayValue: "AVERY",
                                NcptsCode: "CNTYAVERY",
                            },
                            {
                                DisplayValue: "WILKES",
                                NcptsCode: "CNTYWILKE",
                            },
                            {
                                DisplayValue: "GATES",
                                NcptsCode: "CNTYGATES",
                            },
                            {
                                DisplayValue: "ONSLOW",
                                NcptsCode: "CNTYONSLO",
                            },
                            {
                                DisplayValue: "CARTERET",
                                NcptsCode: "CNTYCARTE",
                            },
                            {
                                DisplayValue: "YADKIN",
                                NcptsCode: "CNTYYADKI",
                            },
                            {
                                DisplayValue: "HALIFAX",
                                NcptsCode: "CNTYHALIF",
                            },
                            {
                                DisplayValue: "NORTHAMPTON",
                                NcptsCode: "CNTYNORTH",
                            },
                            {
                                DisplayValue: "ORANGE",
                                NcptsCode: "CNTYORANG",
                            },
                            {
                                DisplayValue: "COLUMBUS",
                                NcptsCode: "CNTYCOLUM",
                            },
                            {
                                DisplayValue: "MOORE",
                                NcptsCode: "CNTYMOORE",
                            },
                            {
                                DisplayValue: "BURKE",
                                NcptsCode: "CNTYBURKE",
                            },
                            {
                                DisplayValue: "CURRITUCK",
                                NcptsCode: "CNTYCURRI",
                            },
                            {
                                DisplayValue: "SAMPSON",
                                NcptsCode: "CNTYSAMPS",
                            },
                            {
                                DisplayValue: "JOHNSTON",
                                NcptsCode: "CNTYJOHNS",
                            },
                            {
                                DisplayValue: "GRAHAM",
                                NcptsCode: "CNTYGRAHA",
                            },
                            {
                                DisplayValue: "CUMBERLAND",
                                NcptsCode: "CNTYCUMBE",
                            },
                            {
                                DisplayValue: "POLK",
                                NcptsCode: "CNTYPOLK",
                            },
                            {
                                DisplayValue: "CHEROKEE",
                                NcptsCode: "CNTYCHERO",
                            },
                            {
                                DisplayValue: "EDGECOMBE",
                                NcptsCode: "CNTYEDGEC",
                            },
                            {
                                DisplayValue: "CHATHAM",
                                NcptsCode: "CNTYCHATH",
                            },
                            {
                                DisplayValue: "SWAIN",
                                NcptsCode: "CNTYSWAIN",
                            },
                            {
                                DisplayValue: "GASTON",
                                NcptsCode: "CNTYGASTO",
                            },
                            {
                                DisplayValue: "MONTGOMERY",
                                NcptsCode: "CNTYMONTG",
                            },
                            {
                                DisplayValue: "HERTFORD",
                                NcptsCode: "CNTYHERTF",
                            },
                            {
                                DisplayValue: "PASQUOTAWK",
                                NcptsCode: "CNTYPASQU",
                            },
                            {
                                DisplayValue: "HARNETT",
                                NcptsCode: "CNTYHARNE",
                            },
                            {
                                DisplayValue: "JONES",
                                NcptsCode: "CNTYJONES",
                            },
                            {
                                DisplayValue: "IREDELL",
                                NcptsCode: "CNTYIREDE",
                            },
                            {
                                DisplayValue: "BLADEN",
                                NcptsCode: "CNTYBLADE",
                            },
                            {
                                DisplayValue: "WARREN",
                                NcptsCode: "CNTYWARRE",
                            },
                            {
                                DisplayValue: "GRANVILLE",
                                NcptsCode: "CNTYGRANV",
                            },
                            {
                                DisplayValue: "PAMLICO",
                                NcptsCode: "CNTYPAMLI",
                            },
                            {
                                DisplayValue: "DUPLIN",
                                NcptsCode: "CNTYDUPLI",
                            },
                            {
                                DisplayValue: "LEE",
                                NcptsCode: "CNTYLEE",
                            },
                            {
                                DisplayValue: "DAVIDSON",
                                NcptsCode: "CNTYDAVID",
                            },
                            {
                                DisplayValue: "WAKE",
                                NcptsCode: "CNTYWAKE",
                            },
                            {
                                DisplayValue: "DARE",
                                NcptsCode: "CNTYDARE",
                            },
                            {
                                DisplayValue: "UNION",
                                NcptsCode: "CNTYUNION",
                            },
                            {
                                DisplayValue: "CALDWELL",
                                NcptsCode: "CNTYCALDW",
                            },
                            {
                                DisplayValue: "BERTIE",
                                NcptsCode: "CNTYBERTI",
                            },
                            {
                                DisplayValue: "MCDOWELL",
                                NcptsCode: "CNTYMCDOW",
                            },
                            {
                                DisplayValue: "RUTHERFORD",
                                NcptsCode: "CNTYRUTHE",
                            },
                            {
                                DisplayValue: "DAVIE",
                                NcptsCode: "CNTYDAVIE",
                            },
                            {
                                DisplayValue: "ROBESON",
                                NcptsCode: "CNTYROBES",
                            },
                            {
                                DisplayValue: "PITT",
                                NcptsCode: "CNTYPITT",
                            },
                        ],
                        OwnerOrders: [
                            {
                                DisplayValue: "OTHERS",
                                NcptsCode: "OWOROTH",
                            },
                            {
                                DisplayValue: "SECONDARY",
                                NcptsCode: "OWORSEC",
                            },
                            {
                                DisplayValue: "PRIMARY",
                                NcptsCode: "OWORPRI",
                            },
                        ],
                    },
                    Owners: [
                        {
                            OwnershipType: "INDIVIDUAL",
                            OwnerName: {
                                LastName: "GLAUBIGER",
                                Suffix: "",
                                MiddleName: "",
                                Additional: "",
                                FirstName: "GEORGE",
                            },
                            COACode: "",
                            GroupNumber: "",
                            OwnerOrderNCPTSCode: "OWORPRI",
                            MailingAddress: {
                                AddressLine3: "",
                                AddressLine2: "",
                                AddressLine1: "2447 NEW BERN AVE",
                                StateNCPTSCode: "STATNC",
                                State: "NORTH CAROLINA",
                                InCareOf: "",
                                PostalCode: "27610",
                                CountyNCPTSLookupCode: "CNTYDURHA",
                                City: "RALEIGH",
                                PostalCodeExtension: "",
                                County: "DURHAM",
                            },
                            Confidential: "False",
                            OwnershipTypeNCPTSCode: "OWTYIND",
                            SendMail: "Y",
                            DBAName: {},
                            PercentOwnership: "100.00",
                            OwnerPk: "882886",
                            OwnerPhoneNumbers: [
                                {
                                    Extension: "",
                                    Phone1: "608",
                                    Type: "HOME",
                                    Phone2: "4459",
                                    AreaCode: "919",
                                    IsDefaultPhone: "Y",
                                    TypeNCPTSLookupCode: "PHTYHOM",
                                },
                                {
                                    Extension: "",
                                    Phone1: "231",
                                    Type: "WORK",
                                    Phone2: "4629",
                                    AreaCode: "919",
                                    IsDefaultPhone: "N",
                                    TypeNCPTSLookupCode: "PHTYWRK",
                                },
                            ],
                            OwnerOrder: "PRIMARY",
                            EligibleForTaxRelief: "False",
                        },
                    ],
                },
                SitusInformation: {
                    StateNCPTSCode: "STATNC",
                    TaxDistricts: [
                        {
                            TaxPercentage: "100",
                            County: "CAPITAL FINANCE",
                            CountyNCPTSLookupCode: "JURS305",
                        },
                        {
                            TaxPercentage: "100",
                            County: "REDWOOD-FD",
                            CountyNCPTSLookupCode: "JURS325",
                        },
                        {
                            TaxPercentage: "100",
                            County: "DURHAM COUNTY",
                            CountyNCPTSLookupCode: "JURS300",
                        },
                    ],
                    Address2: "",
                    PostalCode: "27703",
                    Address3: "",
                    City: "DURHAM",
                    Address1: "1707 BAPTIST ROAD",
                    PostalCodeExtension: "",
                    County: "DURHAM",
                    SitusValidValues: {
                        States: [
                            {
                                DisplayValue: null,
                                NcptsCode: null,
                            },
                            {
                                DisplayValue: "MISSOURI",
                                NcptsCode: "STATMO",
                            },
                            {
                                DisplayValue: "ONTARIO",
                                NcptsCode: "STATON",
                            },
                            {
                                DisplayValue: "COLORADO",
                                NcptsCode: "STATCO",
                            },
                            {
                                DisplayValue: "NEW YORK",
                                NcptsCode: "STATNY",
                            },
                            {
                                DisplayValue: "LOUISIANA",
                                NcptsCode: "STATLA",
                            },
                            {
                                DisplayValue: "IDAHO",
                                NcptsCode: "STATID",
                            },
                            {
                                DisplayValue: "OKLAHOMA",
                                NcptsCode: "STATOK",
                            },
                            {
                                DisplayValue: "PUERTO RICO",
                                NcptsCode: "STATPR",
                            },
                            {
                                DisplayValue: "PENNSYLVANIA",
                                NcptsCode: "STATPA",
                            },
                            {
                                DisplayValue: "VERMONT",
                                NcptsCode: "STATVT",
                            },
                            {
                                DisplayValue: "WISCONSIN",
                                NcptsCode: "STATWI",
                            },
                            {
                                DisplayValue: "INDIANA",
                                NcptsCode: "STATIN",
                            },
                            {
                                DisplayValue: "WYOMING",
                                NcptsCode: "STATWY",
                            },
                            {
                                DisplayValue: "OHIO",
                                NcptsCode: "STATOH",
                            },
                            {
                                DisplayValue: "HAWAII",
                                NcptsCode: "STATHI",
                            },
                            {
                                DisplayValue: "MISSISSIPPI",
                                NcptsCode: "STATMS",
                            },
                            {
                                DisplayValue: "TEXAS",
                                NcptsCode: "STATTX",
                            },
                            {
                                DisplayValue: "NEW MEXICO",
                                NcptsCode: "STATNM",
                            },
                            {
                                DisplayValue: "SOUTH DAKOTA",
                                NcptsCode: "STATSD",
                            },
                            {
                                DisplayValue: "NEW HAMPSHIRE",
                                NcptsCode: "STATNH",
                            },
                            {
                                DisplayValue: "MARYLAND",
                                NcptsCode: "STATMD",
                            },
                            {
                                DisplayValue: "NEBRASKA",
                                NcptsCode: "STATNE",
                            },
                            {
                                DisplayValue: "MICHIGAN",
                                NcptsCode: "STATMI",
                            },
                            {
                                DisplayValue: "KENTUCKY",
                                NcptsCode: "STATKY",
                            },
                            {
                                DisplayValue: "OREGON",
                                NcptsCode: "STATOR",
                            },
                            {
                                DisplayValue: "UTAH",
                                NcptsCode: "STATUT",
                            },
                            {
                                DisplayValue: "ALABAMA",
                                NcptsCode: "STATAL",
                            },
                            {
                                DisplayValue: "SOUTH CAROLINA",
                                NcptsCode: "STATSC",
                            },
                            {
                                DisplayValue: "ARIZONA",
                                NcptsCode: "STATAZ",
                            },
                            {
                                DisplayValue: "NORTHERN MARIANA ISLANDS",
                                NcptsCode: "STATMP",
                            },
                            {
                                DisplayValue: "FEDERATED STATES OF MICRONESIA",
                                NcptsCode: "STATFM",
                            },
                            {
                                DisplayValue: "ARKANSAS",
                                NcptsCode: "STATAR",
                            },
                            {
                                DisplayValue: "NEW JERSEY",
                                NcptsCode: "STATNJ",
                            },
                            {
                                DisplayValue: "IOWA",
                                NcptsCode: "STATIA",
                            },
                            {
                                DisplayValue: "VIRGIN ISLANDS",
                                NcptsCode: "STATVI",
                            },
                            {
                                DisplayValue: "DELAWARE",
                                NcptsCode: "STATDE",
                            },
                            {
                                DisplayValue: "ILLINOIS",
                                NcptsCode: "STATIL",
                            },
                            {
                                DisplayValue: "MONTANA",
                                NcptsCode: "STATMT",
                            },
                            {
                                DisplayValue: "MARSHALL ISLANDS",
                                NcptsCode: "STATMH",
                            },
                            {
                                DisplayValue: "GUAM",
                                NcptsCode: "STATGU",
                            },
                            {
                                DisplayValue: "WASHINGTON",
                                NcptsCode: "STATWA",
                            },
                            {
                                DisplayValue: "CALIFORNIA",
                                NcptsCode: "STATCA",
                            },
                            {
                                DisplayValue: "FLORIDA",
                                NcptsCode: "STATFL",
                            },
                            {
                                DisplayValue: "ARMED FORCES PACIFIC",
                                NcptsCode: "STATAP",
                            },
                            {
                                DisplayValue: "ARMED FORCES AMERICA",
                                NcptsCode: "STATAA",
                            },
                            {
                                DisplayValue: "NORTH DAKOTA",
                                NcptsCode: "STATND",
                            },
                            {
                                DisplayValue: "PALAU",
                                NcptsCode: "STATPW",
                            },
                            {
                                DisplayValue: "KANSAS",
                                NcptsCode: "STATKS",
                            },
                            {
                                DisplayValue: "MINNESOTA",
                                NcptsCode: "STATMN",
                            },
                            {
                                DisplayValue: "GEORGIA",
                                NcptsCode: "STATGA",
                            },
                            {
                                DisplayValue: "NORTH CAROLINA",
                                NcptsCode: "STATNC",
                            },
                            {
                                DisplayValue: "TENNESSEE",
                                NcptsCode: "STATTN",
                            },
                            {
                                DisplayValue: "VIRGINIA",
                                NcptsCode: "STATVA",
                            },
                            {
                                DisplayValue: "DISTRICT OF COLUMBIA",
                                NcptsCode: "STATDC",
                            },
                            {
                                DisplayValue: "CONNECTICUT",
                                NcptsCode: "STATCT",
                            },
                            {
                                DisplayValue: "MAINE",
                                NcptsCode: "STATME",
                            },
                            {
                                DisplayValue: "RHODE ISLAND",
                                NcptsCode: "STATRI",
                            },
                            {
                                DisplayValue: "NEVADA",
                                NcptsCode: "STATNV",
                            },
                            {
                                DisplayValue: "WEST VIRGINIA",
                                NcptsCode: "STATWV",
                            },
                            {
                                DisplayValue: "ALASKA",
                                NcptsCode: "STATAK",
                            },
                            {
                                DisplayValue: "ARMED FORCES AFRICA, CANADA, EUROPE, MIDDLE EAST",
                                NcptsCode: "STATAE",
                            },
                            {
                                DisplayValue: "AMERICAN SAMOA",
                                NcptsCode: "STATAS",
                            },
                            {
                                DisplayValue: "MASSACHUSETTS",
                                NcptsCode: "STATMA",
                            },
                        ],
                        Cities: [
                            {
                                DisplayValue: null,
                                NcptsCode: null,
                            },
                            {
                                DisplayValue: "DURHAM-ANNX-9-MTH",
                                NcptsCode: "JURSAO9",
                            },
                            {
                                DisplayValue: "HILLSBOROUGH",
                                NcptsCode: "JURSHILLSBOROUGH",
                            },
                            {
                                DisplayValue: "DURHAM-ANNX-4-MTH",
                                NcptsCode: "JURSAO4",
                            },
                            {
                                DisplayValue: "RESEARCH-TRIANGLE-PA",
                                NcptsCode: "JURSRESEARCH-TRIANGLE-PA",
                            },
                            {
                                DisplayValue: "DURHAM",
                                NcptsCode: "JURS360",
                            },
                            {
                                DisplayValue: "RALEIGH-ANNX-6-MTH",
                                NcptsCode: "JURSR06",
                            },
                            {
                                DisplayValue: "DURHAM-ANNX-1-MTH",
                                NcptsCode: "JURSAO1",
                            },
                            {
                                DisplayValue: "DURHAM-ANNX-3-MTH",
                                NcptsCode: "JURSAO3",
                            },
                            {
                                DisplayValue: "DURHAM-ANNX-10-MTH",
                                NcptsCode: "JURSA10",
                            },
                            {
                                DisplayValue: "RALEIGH-ANNX-10-MTH",
                                NcptsCode: "JURSR10",
                            },
                            {
                                DisplayValue: "RALEIGH-ANNX-8-MTH",
                                NcptsCode: "JURSR08",
                            },
                            {
                                DisplayValue: "CARY",
                                NcptsCode: "JURS377",
                            },
                            {
                                DisplayValue: "RALEIGH-ANNX-9-MTH",
                                NcptsCode: "JURSR09",
                            },
                            {
                                DisplayValue: "BAHAMA",
                                NcptsCode: "JURSBAHAMA",
                            },
                            {
                                DisplayValue: "RALEIGH-ANNX-5-MTH",
                                NcptsCode: "JURSR05",
                            },
                            {
                                DisplayValue: "MORRISVILLE",
                                NcptsCode: "JURS726",
                            },
                            {
                                DisplayValue: "DURHAM-ANNX-2-MTH",
                                NcptsCode: "JURSAO2",
                            },
                            {
                                DisplayValue: "DURHAM-ANNX-11-MTH",
                                NcptsCode: "JURSA11",
                            },
                            {
                                DisplayValue: "MORSVILL-ANNX-10-MTH",
                                NcptsCode: "JURSM10",
                            },
                            {
                                DisplayValue: "BUTNER",
                                NcptsCode: "JURSBUTNER",
                            },
                            {
                                DisplayValue: "DURHAM-ANNX-7-MTH",
                                NcptsCode: "JURSAO7",
                            },
                            {
                                DisplayValue: "MORSVILL-ANNX-9-MTH",
                                NcptsCode: "JURSM09",
                            },
                            {
                                DisplayValue: "TIMBERLAKE",
                                NcptsCode: "JURSTIMBERLAKE",
                            },
                            {
                                DisplayValue: "RALEIGH-ANNX-7-MTH",
                                NcptsCode: "JURSR07",
                            },
                            {
                                DisplayValue: "MORSVILL-ANNX-3-MTH",
                                NcptsCode: "JURSM03",
                            },
                            {
                                DisplayValue: "DURHAM-ANNX-6-MTH",
                                NcptsCode: "JURSAO6",
                            },
                            {
                                DisplayValue: "RALEIGH-ANNX-1-MTH",
                                NcptsCode: "JURSR01",
                            },
                            {
                                DisplayValue: "RALEIGH-ANNX-4-MTH",
                                NcptsCode: "JURSR04",
                            },
                            {
                                DisplayValue: "CHAPEL HILL",
                                NcptsCode: "JURS375",
                            },
                            {
                                DisplayValue: "ROUGEMONT",
                                NcptsCode: "JURSROUGEMONT",
                            },
                            {
                                DisplayValue: "RALEIGH",
                                NcptsCode: "JURS376",
                            },
                        ],
                        TaxDistricts: [
                            {
                                DisplayValue: "DURHAM-ANNX-9-MTH",
                                NcptsCode: "JURSAO9",
                            },
                            {
                                DisplayValue: "HILLSBOROUGH",
                                NcptsCode: "JURSHILLSBOROUGH",
                            },
                            {
                                DisplayValue: "CAPITAL FINANCE",
                                NcptsCode: "JURS305",
                            },
                            {
                                DisplayValue: "DURHAM-ANNX-4-MTH",
                                NcptsCode: "JURSAO4",
                            },
                            {
                                DisplayValue: "RESEARCH-TRIANGLE-PA",
                                NcptsCode: "JURSRESEARCH-TRIANGLE-PA",
                            },
                            {
                                DisplayValue: "RTP Public Transit",
                                NcptsCode: "JURS346",
                            },
                            {
                                DisplayValue: "BETHESDA-SD",
                                NcptsCode: "JURS311",
                            },
                            {
                                DisplayValue: "DURHAM",
                                NcptsCode: "JURS360",
                            },
                            {
                                DisplayValue: "RALEIGH-ANNX-6-MTH",
                                NcptsCode: "JURSR06",
                            },
                            {
                                DisplayValue: "DURHAM-ANNX-1-MTH",
                                NcptsCode: "JURSAO1",
                            },
                            {
                                DisplayValue: "BAHAMA-FD",
                                NcptsCode: "JURS340",
                            },
                            {
                                DisplayValue: "DURHAM-ANNX-3-MTH",
                                NcptsCode: "JURSAO3",
                            },
                            {
                                DisplayValue: "ENO-FD",
                                NcptsCode: "JURS335",
                            },
                            {
                                DisplayValue: "DURHAM-ANNX-10-MTH",
                                NcptsCode: "JURSA10",
                            },
                            {
                                DisplayValue: "BETHESDA-FD",
                                NcptsCode: "JURS310",
                            },
                            {
                                DisplayValue: "RALEIGH-ANNX-10-MTH",
                                NcptsCode: "JURSR10",
                            },
                            {
                                DisplayValue: "RALEIGH-ANNX-8-MTH",
                                NcptsCode: "JURSR08",
                            },
                            {
                                DisplayValue: "CARY",
                                NcptsCode: "JURS377",
                            },
                            {
                                DisplayValue: "RALEIGH-ANNX-9-MTH",
                                NcptsCode: "JURSR09",
                            },
                            {
                                DisplayValue: "BAHAMA",
                                NcptsCode: "JURSBAHAMA",
                            },
                            {
                                DisplayValue: "PARKWOOD-FD",
                                NcptsCode: "JURS320",
                            },
                            {
                                DisplayValue: "RALEIGH-ANNX-5-MTH",
                                NcptsCode: "JURSR05",
                            },
                            {
                                DisplayValue: "DURHAM-BID",
                                NcptsCode: "JURS361",
                            },
                            {
                                DisplayValue: "MANGUM-BUTNR-SP",
                                NcptsCode: "JURS380",
                            },
                            {
                                DisplayValue: "MORRISVILLE",
                                NcptsCode: "JURS726",
                            },
                            {
                                DisplayValue: "NEW-HOPE-FD",
                                NcptsCode: "JURS330",
                            },
                            {
                                DisplayValue: "DCFR-SD",
                                NcptsCode: "JURS400",
                            },
                            {
                                DisplayValue: "DURHAM-ANNX-2-MTH",
                                NcptsCode: "JURSAO2",
                            },
                            {
                                DisplayValue: "DURHAM-ANNX-11-MTH",
                                NcptsCode: "JURSA11",
                            },
                            {
                                DisplayValue: "MORSVILL-ANNX-10-MTH",
                                NcptsCode: "JURSM10",
                            },
                            {
                                DisplayValue: "BUTNER",
                                NcptsCode: "JURSBUTNER",
                            },
                            {
                                DisplayValue: "DURHAM-ANNX-7-MTH",
                                NcptsCode: "JURSAO7",
                            },
                            {
                                DisplayValue: "MORSVILL-ANNX-9-MTH",
                                NcptsCode: "JURSM09",
                            },
                            {
                                DisplayValue: "LEBANON-FD",
                                NcptsCode: "JURS315",
                            },
                            {
                                DisplayValue: "TIMBERLAKE",
                                NcptsCode: "JURSTIMBERLAKE",
                            },
                            {
                                DisplayValue: "DURHAM COUNTY",
                                NcptsCode: "JURS300",
                            },
                            {
                                DisplayValue: "RALEIGH-ANNX-7-MTH",
                                NcptsCode: "JURSR07",
                            },
                            {
                                DisplayValue: "MORSVILL-ANNX-3-MTH",
                                NcptsCode: "JURSM03",
                            },
                            {
                                DisplayValue: "RTP Special",
                                NcptsCode: "JURS345",
                            },
                            {
                                DisplayValue: "DURHAM-ANNX-6-MTH",
                                NcptsCode: "JURSAO6",
                            },
                            {
                                DisplayValue: "RALEIGH-ANNX-1-MTH",
                                NcptsCode: "JURSR01",
                            },
                            {
                                DisplayValue: "RALEIGH-ANNX-4-MTH",
                                NcptsCode: "JURSR04",
                            },
                            {
                                DisplayValue: "CHAPEL HILL",
                                NcptsCode: "JURS375",
                            },
                            {
                                DisplayValue: "ROUGEMONT",
                                NcptsCode: "JURSROUGEMONT",
                            },
                            {
                                DisplayValue: "REDWOOD-FD",
                                NcptsCode: "JURS325",
                            },
                            {
                                DisplayValue: "RALEIGH",
                                NcptsCode: "JURS376",
                            },
                        ],
                        Counties: [
                            {
                                DisplayValue: null,
                                NcptsCode: null,
                            },
                            {
                                DisplayValue: "DURHAM",
                                NcptsCode: "CNTYDURHA",
                            },
                        ],
                    },
                    IsDetailed: "Y",
                    CountyNCPTSCode: "CNTYDURHA",
                    State: "NORTH CAROLINA",
                    SitusPk: "98654",
                    CityNCPTSCode: "JURS360",
                },
                ContactInfo: {
                    ContactAddress: {
                        AddressLine3: null,
                        AddressLine2: null,
                        AddressLine1: null,
                        StateNCPTSCode: null,
                        State: null,
                        InCareOf: null,
                        PostalCode: null,
                        CountyNCPTSLookupCode: null,
                        City: null,
                        PostalCodeExtension: null,
                        County: null,
                    },
                    ContactPhone: {
                        Extension: null,
                        AreaCode: null,
                        Phone1: null,
                        Type: null,
                        Phone2: null,
                        TypeNCPTSLookupCode: null,
                    },
                    ContactValidValues: {
                        States: [
                            {
                                DisplayValue: null,
                                NcptsCode: null,
                            },
                            {
                                DisplayValue: "MISSOURI",
                                NcptsCode: "STATMO",
                            },
                            {
                                DisplayValue: "ONTARIO",
                                NcptsCode: "STATON",
                            },
                            {
                                DisplayValue: "COLORADO",
                                NcptsCode: "STATCO",
                            },
                            {
                                DisplayValue: "NEW YORK",
                                NcptsCode: "STATNY",
                            },
                            {
                                DisplayValue: "LOUISIANA",
                                NcptsCode: "STATLA",
                            },
                            {
                                DisplayValue: "IDAHO",
                                NcptsCode: "STATID",
                            },
                            {
                                DisplayValue: "OKLAHOMA",
                                NcptsCode: "STATOK",
                            },
                            {
                                DisplayValue: "PUERTO RICO",
                                NcptsCode: "STATPR",
                            },
                            {
                                DisplayValue: "PENNSYLVANIA",
                                NcptsCode: "STATPA",
                            },
                            {
                                DisplayValue: "VERMONT",
                                NcptsCode: "STATVT",
                            },
                            {
                                DisplayValue: "WISCONSIN",
                                NcptsCode: "STATWI",
                            },
                            {
                                DisplayValue: "INDIANA",
                                NcptsCode: "STATIN",
                            },
                            {
                                DisplayValue: "WYOMING",
                                NcptsCode: "STATWY",
                            },
                            {
                                DisplayValue: "OHIO",
                                NcptsCode: "STATOH",
                            },
                            {
                                DisplayValue: "HAWAII",
                                NcptsCode: "STATHI",
                            },
                            {
                                DisplayValue: "MISSISSIPPI",
                                NcptsCode: "STATMS",
                            },
                            {
                                DisplayValue: "TEXAS",
                                NcptsCode: "STATTX",
                            },
                            {
                                DisplayValue: "NEW MEXICO",
                                NcptsCode: "STATNM",
                            },
                            {
                                DisplayValue: "SOUTH DAKOTA",
                                NcptsCode: "STATSD",
                            },
                            {
                                DisplayValue: "NEW HAMPSHIRE",
                                NcptsCode: "STATNH",
                            },
                            {
                                DisplayValue: "MARYLAND",
                                NcptsCode: "STATMD",
                            },
                            {
                                DisplayValue: "NEBRASKA",
                                NcptsCode: "STATNE",
                            },
                            {
                                DisplayValue: "MICHIGAN",
                                NcptsCode: "STATMI",
                            },
                            {
                                DisplayValue: "KENTUCKY",
                                NcptsCode: "STATKY",
                            },
                            {
                                DisplayValue: "OREGON",
                                NcptsCode: "STATOR",
                            },
                            {
                                DisplayValue: "UTAH",
                                NcptsCode: "STATUT",
                            },
                            {
                                DisplayValue: "ALABAMA",
                                NcptsCode: "STATAL",
                            },
                            {
                                DisplayValue: "SOUTH CAROLINA",
                                NcptsCode: "STATSC",
                            },
                            {
                                DisplayValue: "ARIZONA",
                                NcptsCode: "STATAZ",
                            },
                            {
                                DisplayValue: "NORTHERN MARIANA ISLANDS",
                                NcptsCode: "STATMP",
                            },
                            {
                                DisplayValue: "FEDERATED STATES OF MICRONESIA",
                                NcptsCode: "STATFM",
                            },
                            {
                                DisplayValue: "ARKANSAS",
                                NcptsCode: "STATAR",
                            },
                            {
                                DisplayValue: "NEW JERSEY",
                                NcptsCode: "STATNJ",
                            },
                            {
                                DisplayValue: "IOWA",
                                NcptsCode: "STATIA",
                            },
                            {
                                DisplayValue: "VIRGIN ISLANDS",
                                NcptsCode: "STATVI",
                            },
                            {
                                DisplayValue: "DELAWARE",
                                NcptsCode: "STATDE",
                            },
                            {
                                DisplayValue: "ILLINOIS",
                                NcptsCode: "STATIL",
                            },
                            {
                                DisplayValue: "MONTANA",
                                NcptsCode: "STATMT",
                            },
                            {
                                DisplayValue: "MARSHALL ISLANDS",
                                NcptsCode: "STATMH",
                            },
                            {
                                DisplayValue: "GUAM",
                                NcptsCode: "STATGU",
                            },
                            {
                                DisplayValue: "WASHINGTON",
                                NcptsCode: "STATWA",
                            },
                            {
                                DisplayValue: "CALIFORNIA",
                                NcptsCode: "STATCA",
                            },
                            {
                                DisplayValue: "FLORIDA",
                                NcptsCode: "STATFL",
                            },
                            {
                                DisplayValue: "ARMED FORCES PACIFIC",
                                NcptsCode: "STATAP",
                            },
                            {
                                DisplayValue: "ARMED FORCES AMERICA",
                                NcptsCode: "STATAA",
                            },
                            {
                                DisplayValue: "NORTH DAKOTA",
                                NcptsCode: "STATND",
                            },
                            {
                                DisplayValue: "PALAU",
                                NcptsCode: "STATPW",
                            },
                            {
                                DisplayValue: "KANSAS",
                                NcptsCode: "STATKS",
                            },
                            {
                                DisplayValue: "MINNESOTA",
                                NcptsCode: "STATMN",
                            },
                            {
                                DisplayValue: "GEORGIA",
                                NcptsCode: "STATGA",
                            },
                            {
                                DisplayValue: "NORTH CAROLINA",
                                NcptsCode: "STATNC",
                            },
                            {
                                DisplayValue: "TENNESSEE",
                                NcptsCode: "STATTN",
                            },
                            {
                                DisplayValue: "VIRGINIA",
                                NcptsCode: "STATVA",
                            },
                            {
                                DisplayValue: "DISTRICT OF COLUMBIA",
                                NcptsCode: "STATDC",
                            },
                            {
                                DisplayValue: "CONNECTICUT",
                                NcptsCode: "STATCT",
                            },
                            {
                                DisplayValue: "MAINE",
                                NcptsCode: "STATME",
                            },
                            {
                                DisplayValue: "RHODE ISLAND",
                                NcptsCode: "STATRI",
                            },
                            {
                                DisplayValue: "NEVADA",
                                NcptsCode: "STATNV",
                            },
                            {
                                DisplayValue: "WEST VIRGINIA",
                                NcptsCode: "STATWV",
                            },
                            {
                                DisplayValue: "ALASKA",
                                NcptsCode: "STATAK",
                            },
                            {
                                DisplayValue: "ARMED FORCES AFRICA, CANADA, EUROPE, MIDDLE EAST",
                                NcptsCode: "STATAE",
                            },
                            {
                                DisplayValue: "AMERICAN SAMOA",
                                NcptsCode: "STATAS",
                            },
                            {
                                DisplayValue: "MASSACHUSETTS",
                                NcptsCode: "STATMA",
                            },
                        ],
                        PhoneTypes: [
                            {
                                DisplayValue: null,
                                NcptsCode: null,
                            },
                            {
                                DisplayValue: "PHONETREE",
                                NcptsCode: "PHTYPHTR",
                            },
                            {
                                DisplayValue: "HOME",
                                NcptsCode: "PHTYHOM",
                            },
                            {
                                DisplayValue: "E-PAYER",
                                NcptsCode: "PHTYEPAY",
                            },
                            {
                                DisplayValue: "FAX",
                                NcptsCode: "PHTYFAX",
                            },
                            {
                                DisplayValue: "CELLULAR",
                                NcptsCode: "PHTYCEL",
                            },
                            {
                                DisplayValue: "WORK",
                                NcptsCode: "PHTYWRK",
                            },
                            {
                                DisplayValue: "UNKNOWN",
                                NcptsCode: "PHTYUNK",
                            },
                        ],
                        Counties: [
                            {
                                DisplayValue: null,
                                NcptsCode: null,
                            },
                            {
                                DisplayValue: "DURHAM",
                                NcptsCode: "CNTYDURHA",
                            },
                        ],
                        BusinessTypes: [
                            {
                                DisplayValue: null,
                                NcptsCode: null,
                            },
                            {
                                DisplayValue: "DOING BUSINESS AS",
                                NcptsCode: "BUNTDBA",
                            },
                            {
                                DisplayValue: "FORMER",
                                NcptsCode: "BUNTFRMR",
                            },
                            {
                                DisplayValue: "TRADE",
                                NcptsCode: "BUNTTRAD",
                            },
                            {
                                DisplayValue: "AS KNOWN AS",
                                NcptsCode: "BUNTAKA",
                            },
                        ],
                    },
                    ContactEmail: "",
                    ContactName: {},
                },
                PropertyInfo: {
                    ManufacturedHomeValidValues: {
                        Parks: [
                            null,
                            "145 : Century Mobile Home Park",
                            "146 : GLENWOOD MOBILE HOME PARK",
                            "147 : Gorman Village Mobile Home Park",
                            "148 : LITTLE BITS MOBILE HOME PARK",
                            "149 : MINERAL SPRINGS MOBILE HOME PARK LLC",
                            "151 : PARRISH MHP",
                            "150 : WHISPERING PINES MHP INC",
                        ],
                        BodyTypes: [
                            null,
                            "CT-Camping Trailer",
                            "HC-House Car",
                            "LT-Light Truck",
                            "MH-Motor Home",
                            "MS-Multi Section",
                            "OTHER-Other",
                            "SS-Single Section",
                            "TT-Travel Trailer",
                        ],
                    },
                    VehicleValidValues: {
                        BodyTypes: [
                            null,
                            "2D-2 Door",
                            "2H-2 Door Hardtop",
                            "2S-2 Door Sedan",
                            "2T-2 Door Hardtop",
                            "3D-3 Door",
                            "3S-3 Door Sedan",
                            "4D-4 Door",
                            "4H-4 Door Hardtop",
                            "4S-4 Door Sedan",
                            "4T-4 Door Hardtop",
                            "5S-5 Door Sedan (HthBk)",
                            "AM-Ambulance",
                            "AT-Horse/Cattle Trailer",
                            "BC-Bottle Carrier",
                            "BT-Boat Trailer",
                            "BU-Bus",
                            "CM-Concrete Mixer",
                            "CN-Convertible",
                            "CP-Coupe",
                            "CT-Camping Trailer",
                            "DP-Dump Body",
                            "EMPTY-Empty",
                            "GC-Golf Cart",
                            "HB-House Boat",
                            "HC-House Car",
                            "HR-Hearse",
                            "JP-Jeep",
                            "LG-Lift Gate",
                            "LM-Limousine",
                            "LS-Low Speed",
                            "LT-Light Truck",
                            "MC-Motorcycle",
                            "MH-Motor Home",
                            "MP-Personal Utility Veh",
                            "MS-Multi Section",
                            "NA-No Added Value",
                            "OTHER-Other",
                            "PG-Plate Glass",
                            "PT-Propane Tanker",
                            "RD-Roadster",
                            "RF-Refrigerated",
                            "RV-Recreational Vehicle",
                            "SD-Sedan",
                            "SF-Stake/Flat",
                            "SP-SP Mobile Equp",
                            "SS-Single Section",
                            "SW-Station Wagon",
                            "TC-Trash Compactor",
                            "TD-Tandem Dump",
                            "TG-Touring Car",
                            "TK-Truck",
                            "TL-Trailer",
                            "TN-Tanker",
                            "TR-Truck Tractor",
                            "TT-Travel Trailer",
                            "UK-Unknown",
                            "UL-Utility Trailer",
                            "UT-Utility Trailer",
                            "UVTC-Tent Camper",
                            "VN-Van",
                            "WC-Wheel Chair",
                            "WK-Wrecker",
                            "WL-Wrecker Large",
                            "WM-Wrecker Mini",
                            "WT-Wrecker Tilt",
                        ],
                    },
                    Misc: [{
                            "PropertyPK": "",
                            "GroupDescription": "",
                            "PropertyDescription": "",
                            "AppraisedValue": "",
                            "PriorYearValue": "",
                            "VoidEffectiveDate": "",
                            "YearAcquired": "",
                            "OriginalCost": "",
                        }],
                    UnregisteredVehicles: [],
                    Watercraft: [],
                    PermanentPlateVehicles: [],
                    AircraftValidValues: {
                        Airports: [null, "153 : LAKE RIDGE AERO PARK"],
                        BodyTypes: [
                            null,
                            "2D-2 Door",
                            "4D-4 Door",
                            "AC-Airplane",
                            "ACHB-Hot Air Balloon",
                            "EX-Experimental",
                            "GL-Glider",
                            "HB-House Boat",
                            "HE-Helicopter",
                            "NA-No Added Value",
                            "OTHER-Other",
                            "PG-Plate Glass",
                            "PT-Propane Tanker",
                            "RF-Refrigerated",
                            "SF-Stake/Flat",
                            "UY-UY",
                        ],
                    },
                    Aircraft: [],
                    WatercraftValidValues: {
                        Marinas: [null, "152 : ROLLINGVIEW MARINA"],
                        ModelTypes: [
                            null,
                            "CB-Cabin",
                            "HB-House Boat",
                            "OP-Open",
                            "OT-Other",
                            "PT-Pontoon",
                            "PW-Personal Watercraft",
                        ],
                        PrimaryUseCodes: [
                            null,
                            "CF-Commercial Fishing",
                            "CP-Commercial Passenger",
                            "DM-Demonstration",
                            "LI-Livery",
                            "OC-Other Commercial",
                            "OT-Other",
                            "PL-Pleasure",
                        ],
                        PropulsionCodes: [
                            null,
                            "IB-Inboard",
                            "IO-Inboard/Outboard",
                            "JS-Jetdrive Engine",
                            "OB-Outboard",
                            "OT-Other",
                            "SB-Sailboat Only",
                            "SI-Aux Sail Inboard",
                            "SO-Aux Sail Outboard",
                        ],
                        FuelCodes: [
                            null,
                            "DSL-Diesel",
                            "ELC-Electric",
                            "GAS-Gas",
                            "OTH-Other",
                        ],
                    },
                    ManufacturedHomes: [
                        {
                            ParcelID: "",
                            PlateNumber: "",
                            Occupant: "",
                            TitleName1: "",
                            TitleName2: "",
                            LotNumberExtension: "",
                            Make: "CONN",
                            BuildingPermitNumber: "",
                            Park: null,
                            PropertyPk: "2440892",
                            MovingPermitIssueDate: "",
                            Length: 60,
                            BuildingPermitIssueDate: "",
                            AcquisitionCost: "",
                            Model: "",
                            VIN: "*******KN9767",
                            ModelYear: "1973",
                            MovingPermitNumber: "",
                            Body: "MH-Motor Home",
                            Width: 12,
                            LotNumber: "",
                            YearAcquired: "1988",
                            TitleNumber: "***510593",
                        },
                    ],
                },
                AbstractInfo: {
                    Status: "ABSSMAIL",
                    ListingDate: "",
                    ExtensionRequestDate: "",
                    ExtensionDate: "",
                },
            },
        },
    ],
};
